<template>
  <div class="main-container">
    <b-card class="card shadow">
      <div style="text-align: center">
        <img
          src="@/assets/mts-logo.png"
          alt="logo"
          class="logo-login mb-5 mt-2"
        />
      </div>

      <div v-if="isLoading" id="spinner" class="align-middle">
        <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
      </div>
      <div v-else-if="!isLoading && gotMessageFromServer">
        <b-card-text style="font-weight: bold">{{isAGoodMessage ? 'Buone notizie!' : 'Ops!'}}</b-card-text> 
        <b-card-text>
          {{isAGoodMessage ? "Ti abbiamo inviato un link per generare una nuova password all' indirizzo email che ci hai fornito." : 'Errore imprevisto. Riprovare più tardi.'}}

        </b-card-text>
        <b-button
          variant="primary"
          id="return-to-login-button"
          @click="$router.push({ name: 'login' })"
          >Torna al login</b-button
        >
      </div>
      <div v-else>

      <b-card-text style="font-weight: bold">Resetta password</b-card-text>
      <b-card-text>Inserisci scegli una nuova password di almeno 5 caratteri
      </b-card-text>
      <b-form-group id="password-field" label="Password" label-for="password">
        <b-form-input
          id="password"
          type="password"
          placeholder="Inserisci una nuova password"
          :state="passwordError"
          v-model="password"
          trim
        ></b-form-input>
      </b-form-group>

      <b-form-group id="repeat-password-field" label="Ripeti Password" label-for="repeat-password">
        <b-form-input
          id="repeat-password"
          type="password"
          placeholder="Ripeti la nuova password"
          :state="passwordError"
          v-model="repeatPassword"
          trim
        ></b-form-input>
      </b-form-group>

      <b-button variant="primary" id="reset-password-button" @click="submit()"
        >Resetta password</b-button
      >
      <router-link to="/login">Torna al log in</router-link>
    </div>
        </b-card>
  </div>
</template>

<script>
import {setPasswordWithTokens} from "@/services/APIcalls.js";

export default {
  methods: {
    submit() {
      this.hasBeenSubmitted = true;
      if(!this.passwordError) return;
      this.isLoading = true;
      setPasswordWithTokens(this.password, this.token, this.emailMd5)
      .then( () => {
        this.isAGoodMessage = true;
      })
      .catch( () => {
        this.isAGoodMessage = false;
      })
      .finally(()=> {
        this.isLoading = false;
        this.gotMessageFromServer = true;
      } )
    },
  },
  data() {
    return {        
      token: this.$route.params.token,
      emailMd5: this.$route.params.emailMd5,
      password: "",
      repeatPassword : "",
      hasBeenSubmitted: false,
      isLoading: false,
      gotMessageFromServer: false,
      isAGoodMessage: false
    };
  },
  computed: {
    passwordError (){
      if(!this.hasBeenSubmitted) return null;
      return (this.password == this.repeatPassword && this.password.length > 5);
    }
  }
};
</script>

<style scoped>
.card {
  max-width: 400px;
  max-height: 532px;
  border-style: none;
  padding: 0 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

#reset-password-button {
  position: absolute;
  right: 24px;
  bottom: 24px;
  width: fit-content;
}

.form-group {
  padding-bottom: 20px;
}
.btn {
  width: 150px;
}

a {
  position: absolute;
  bottom: -42px;
  left: 50%;
  transform: translateX(-50%);
  padding: 14px;
}

#spinner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
  transform: translateX(-50%);
}
</style>
